import React, { useContext } from 'react';
import { useEffect } from 'react';
import ALink from '~/components/features/alink';
import { useRouter } from 'next/router';
import { LangContext } from '~/context/lang';

function ErrorPage() {
    let { getLang } = useContext(LangContext);
    let language = getLang("otherPage");
    let router = useRouter();

    useEffect(() => {
        let url = router.asPath;
        if (url.indexOf('404') > 0) {
            document.querySelector('.header').classList.add('fixed');
        }
    }, [router.isReady])
    return (
        <div className="main mt-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 d-flex align-items-center">
                        <div className="error-content position-relative">
                            <div className="container">
                                <h1 className="error-title">{language.error404}</h1>
                                <h6>{language.error404Text}</h6>
                                <p>{language.error404Text2}</p>
                                <ALink href="/" className="btn btn-primary p-4 mt-3" style={{ height: '5rem' }}>
                                    <span>{language.backToHomepage}</span>
                                </ALink>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <img src='/images/404.svg' alt="404" />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default React.memo(ErrorPage);